/*frontend/src/basicChatPage/styles/basicChatEditor.css */
/* General Layout for BasicChatEditor */
.basic-chat-editor-layout {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    align-items: flex-start;
    height: 100%; /* Full height layout */
    background-color: #141c27;
  }
  .basic-chat-editor-layout .Main-Content {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    background-color: #141c27;
        
  }
  
  /* Left Column: Chatter Andy Management */
  .left-column {
    flex-grow: 1;
    max-width: 300px;
    min-width: 250px; /* Set a fixed width for the left column */
    margin-right: 20px;
  }
  
  /* Middle Column: Chat Timeline */
  .middle-column {
    flex-grow: 2;
    margin-right: 20px; /* Space between columns */
    max-width: 950px;
    min-width: 800px;
    max-height: 65vh;
  }
  
  /* Right Column: Chat Playback */
  .right-column { /* Set the width of the chat playback panel */
    max-width: 500px;
    min-width: 400px;
    width: 400px;
    position: static;
    top: 58px;
    right: 0;
    min-height: calc(90vh - 75px);
    max-height: calc(90vh - 75px);
    border-top: #ffffff 1px solid;
    overflow-y: hidden;
  }



.basic-chat-editor-page {
  background-color: #141c27; /* Example background color */
  flex: auto;
  height: 100%;
  width: 100%;
}

