/* src/accountPage/styles/Overview.css */
.overview-container {
    margin-top: 50px;
    background-color: #141c27;
    border-radius: 10px;
    padding-bottom: 20px;
    
  }
  
  .overview-title {
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .overview-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px;
    height: 300px;
  }
  
  .overview-item {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    color: #141c27;
    flex: 1;
    transition: background-color 0.3s, color 0.3s;
    border: 1px solid #ffffff;
    box-shadow: 0 0px 40px rgba(255, 255, 255, 0.3);
  }
  
  .overview-item:hover {
    box-shadow: 0 0px 40px rgba(255, 255, 255, 0.7);
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 10px 0;
  }
  
  .item-title {
    border-bottom: 1px solid #DDE6ED;
    margin: 5px;
    text-align: center;
  }

  .subscription-status {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-left: 5px;
  }
  
  .status-indicator {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .icon-subscribed {
    color: #4caf50; /* Green color for check icon */
    font-size: 1.5em;
    margin-right: 10px;
  }
  
  .icon-not-subscribed {
    color: #f44336; /* Red color for X icon */
    font-size: 1.5em;
    margin-right: 10px;
  }
  
  .token-balance {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 5px;
    gap: 5px;
    
  }
  
  .token-icon {
    color: #ffd700; /* Gold color for token icon */
    font-size: 1.5em;
    margin-right: 10px;
  }

  .overview-item-profile {
    display: flex; 
    flex-direction: column; /* Column layout */
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    color: #141c27;
    flex: 1;
    transition: background-color 0.3s, color 0.3s;
    border: 1px solid #ffffff;
    box-shadow: 0 0px 40px rgba(255, 255, 255, 0.3);
  }

  .overview-item-profile:hover {
    box-shadow: 0 0px 40px rgba(255, 255, 255, 0.7);
  }
  
  .profile-header {
    border-bottom: 1px solid #DDE6ED; /* Border under "Profile" title */
    text-align: center;
    margin-bottom: 10px;
  }
  
  .profile-content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .profile-login, .profile-connections {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-login {
    border-right: 1px solid #ffffff; /* Divider */
    padding-right: 10px;
  }
  
  .profile-connections {
    padding-left: 10px;
  }

  .item-secondary-title-profile {
    color: #141c27;
    margin-bottom: 25px;
    text-align: center;
    top: 0; /* Stick to the top of the container */
    
    z-index: 1; /* Ensure it stays on top */
}

.connection-status {
  display: flex;
  align-items: center;
}

.icon-connected {
  color: #4CAF50; /* Green for connected */
  margin-right: 5px;
}

.icon-not-connected {
  color: #F44336; /* Red for not connected */
  margin-right: 5px;
}


.twitchname-text {
  color: #F44336; /* Make the Twitch name white */
  font-weight: bold; /* Optional: to make it more visible */
}