/* Modal Customizations */
.modal-content {
    background-color: transparent; /* Set background to transparent */
    border: none; /* Remove the border */
    box-shadow: none; /* Remove the box-shadow */
}

/* Remove padding from the modal body */
.modal-body {
    padding: 0;
    background-color: transparent; /* Make the modal body transparent */
}

/* Ensure that the modal dialog stays centered without any padding or margins */
.modal-dialog {
    max-width: none;
    margin: 0;
    top: -50px; /* Adjusting modal position to be 100px higher */
}

/* Customize the close button */
.modal-header .close {
    color: white;
    opacity: 1;
}

/* Optionally, modify the modal header to match your theme */
.modal-header {
    background-color: transparent;
    border-bottom: none;
}
