/* src/components/LivePage/PopoutChatComponent.css */
html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Popout Chat CSS */
.popout-chat-container-v2 {
    width: 100%;
    height: 100%;
    background-color: #ffffff; /* Light mode background */
    color: #000000; /* Light mode text color */
    display: flex;
    flex-direction: column;
    padding: 0; /* Ensure no padding */
    margin: 0; /* Ensure no margin */
    overflow: hidden;
}

/* Messages container */
.popout-messages-container-v2 {
    flex-grow: 1;
    min-height: 200px;
    min-width: 200px;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.popout-message-v2 {
    margin-bottom: 0 !important; 
    padding: 3px 0 !important; 
    align-items: inline-start; 
    background-color: #ffffff !important; 
    overflow-wrap: break-word; 
    margin-left: 11px;
    white-space: normal;
    font-family: "Roboto", Arial, sans-serif !important; /* Match Twitch font */
    font-size: 12.8px !important; /* Keep the size you like */
    font-weight: normal !important; /* Set default font weight */
}

.popout-badge-img-v2 {
    width: 18px !important;  /* Standard Twitch badge size */
    height: 18px !important;
    margin-right: 5px !important;  /* Space between badges */
    vertical-align: middle !important;  /* Align badges vertically with the text */
    object-fit: contain !important;  /* Ensure the image doesn't stretch */
}

.popout-username-v2 {
    font-weight: bold;
    margin-right: 1px;
    color: inherit; /* Keep original color for username */
}

.popout-colon-v2 {
    margin-right: 3px;
}

.popout-content-v2 {
    word-wrap: break-word;
}

.popout-content-v2 img {
    width: 24px !important;  /* Set emote width */
    height: 24px !important;  /* Set emote height */
    vertical-align: middle !important;  /* Align emotes with text */
    object-fit: contain !important;  /* Ensure aspect ratio is maintained */
}

.fake-chat-display-div-v2 {
    background-color: #ffffff;
}

.fake-chat-input-container-v2 {
    background-color: #ffffff;
}

.fake-chat-input-v2 {
    width: 100%;
    border: none;
    background-color: transparent;
}

.fake-chat-button-v2 {
    background-color: #141C27;
    color: white;
    padding: 7px 12px;
    border-radius: 4px;
    border: none;
}

/* Fake Chat Component */
.fake-chat-component {
    display: flex;
    flex-direction: column;
}

.fake-chat-input-container {
    display: flex;
    align-items: center;
    background-color: #ffffff; /* Light mode background */
}

.fake-chat-input {
    flex-grow: 1;
    padding: 5px;
    border: none;
    outline: none;
    background-color: transparent;
}

.chat-input-icons {
    display: flex;
}

.icon-bits,
.icon-emotes {
    width: 18px;
    height: 18px;
    margin: 0 5px;
    cursor: pointer;
}

.chat-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: #ffffff; /* Light mode background */
}

.fake-counter {
    display: flex;
    align-items: center;
}

.icon-diamond,
.icon-settings {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.fake-settings {
    cursor: pointer;
}

.fake-chat-button {
    padding: 7px 12px;
    background-color: #141C27;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Dark mode main container */
.dark-mode-v2 .popout-chat-container-v2 {
    background-color: #141C27 !important; /* Dark mode background */
    color: #ffffff !important; /* Dark mode text color */
}

/* Dark mode messages container */
.dark-mode-v2 .popout-messages-container-v2 {
    background-color: #141C27 !important;
    color: #ffffff !important; /* White text */
}

/* Dark mode message styling */
.dark-mode-v2 .popout-message-v2 {
    background-color: #141C27 !important; /* Dark message background */
    color: inherit; /* Keep original color for username */
}

/* Dark mode username styling (unchanged) */
.dark-mode-v2 .popout-username-v2 {
    color: inherit; /* Keep original color for username */
}

/* Colon in dark mode */
.dark-mode-v2 .popout-colon-v2 {
    color: #ffffff !important; /* White colon in dark mode */
}

/* Content in dark mode */
.dark-mode-v2 .popout-content-v2 {
    color: #ffffff !important; /* White message content */
}

/* Dark mode input container */
.dark-mode-v2 .fake-chat-input-container-v2,
.dark-mode-v2 .fake-chat-input-container {
    background-color: #141C27 !important; /* Blue background */
    border: 1px solid #ffffff;
}

/* Dark mode fake chat input */
.dark-mode-v2 .fake-chat-input-v2,
.dark-mode-v2 .fake-chat-input {
    background-color: #141C27 !important; /* Blue background */
    color: #ffffff !important; /* White text */
}

/* Dark mode chat button */
.dark-mode-v2 .fake-chat-button-v2,
.dark-mode-v2 .fake-chat-button {
    background-color: #526D82 !important; /* Blue background */
    color: #ffffff !important; /* White text */
}

/* Dark mode fake chat display */
.dark-mode-v2 .fake-chat-display-div-v2,
.dark-mode-v2 .fake-chat-component {
    background-color: #141C27 !important; /* Blue background */
    color: #ffffff !important; /* White text */
}

