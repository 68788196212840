/* frontend/src/basicChatPage/styles/basicChatterSettings.css */

/* Container for the chatter settings */
.basic-chatter-settings {
    background-color: #27374D;
    
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-height: 700px;
}

/* Heading for the chatter settings (smaller like "Chatter Andys") */
.basic-chatter-settings h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffffff; /* Changed to white */
}

/* Form group container */
.form-group {
    margin-bottom: 15px;
}

/* Labels for settings sections */
.basic-chatter-settings label {
    font-size: 15px;
    color: #ffffff; /* Changed to white */
}

.badge-icon {
    width: 16px;
    height: 16px;
    margin-right: 2px; /* Space between badges */
    min-height: 16px;
  }

  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    background-color: #DDE6ED; /* Updated background color */
    border: 1px solid #DDE6ED;
    border-radius: 6px;
    box-sizing: border-box;
}

/* ChromePicker container to add spacing and alignment */
.basic-chatter-settings .chrome-picker {
    margin-top: 10px;
}

/* Save button styling, aligned with other buttons in the app */
.basic-chatter-settings button {
    background-color: #a3d9a5; /* Soft green background */
    color: #ffffff; /* White text */
    border: none;
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.basic-chatter-settings button:hover {
    background-color: #5da773; /* Darker green for hover */
}

/* Specific section for "username settings", "Username", "Badges", and "Color" */
.basic-chatter-settings .username-settings h2,
.basic-chatter-settings .username-settings label,
.basic-chatter-settings .badge-settings h2,
.basic-chatter-settings .badge-settings label,
.basic-chatter-settings .color-settings h2,
.basic-chatter-settings .color-settings label {
    color: #ffffff; /* Changed to white for Username, Badges, Color settings */
}


.badge-selection-grid {
    display: flex;
    flex-wrap: wrap;
    background-color: #526D82;
}

.badge-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: border 0.2s, box-shadow 0.2s;
    margin: 5px;
    min-width: 50px ;
    background-color: #9DB2BF;
}

.badge-option img {
    width: 16px;
    height: 16px;
}

.selected-badge {
    border: 2px solid #DDE6ED;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    min-width: 50px ;
}

.selected-badges {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Space between badges and input field */
  }

.color-selection-grid {
    display: flex;
    flex-wrap: wrap;
    background-color: #526D82;
    align-items: left;
    max-width: 180px;
    border-radius: 5px;
}

.color-square {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.2s ease;
    align-items: left;
    margin: 1px;
}

.color-square:hover {
    transform: scale(1.1);
}

.form-control {
    flex-grow: 1; /* Ensure the input field takes up the remaining space */
    padding-left: 10px; /* Add some padding so the text doesn't touch the edge */
  }
