/* Emote picker modal content */
.emote-picker-modal {
    background-color: #2c2f33;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 300px;
    max-height: 200px;
    overflow-y: auto; /* Scroll if content overflows */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.emote-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.2s;
}

.emote-icon:hover {
    transform: scale(1.2); /* Slight zoom on hover */
}
