/* src/components/LivePage/LiveChatterManagement.css */
/* Main container for the chatter management */
.chatter-management {
    background-color: #27374D; /* Updated background color */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    max-height: 1100px; /* Consistent height */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spacing between sections */
    color: #DDE6ED; /* Updated text color */
    border: 1px solid #ffffff;
}

/* Tabs and sections */
.chatter-tabs {
    margin-bottom: 20px; /* Add spacing below the tabs */
}

/* Chatter list styling */
.chatter-list {
    max-height: 378px; /* Consistent height with SessionEditor */
    overflow-y: auto;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #526D82; /* Updated background color */
    border-radius: 10px;
    border: 1px solid #ffffff; /* Updated border */
}

/* Individual chatter item styling */
.chatter-item {
    background-color: #9DB2BF; /* Updated item background */
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
    color: #27374D; /* Updated text color */
}

.chatter-item:hover {
    background-color: #DDE6ED; /* Hover state */
}

/* Input and select styling */
.chatter-item input,
.chatter-item select {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #9DB2BF; /* Updated input border */
    width: 100%;
}

/* Button styling */
.chatter-item button {
    background-color: #f4a6a6;
    border: 1px solid #f4a6a6;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    color: white;
    font-weight: bold;
    align-self: right;
}

/* Generator section */
/* Generator section */
.chatter-generator {
    background-color: #526D82; /* Updated background */
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    color: #DDE6ED; /* Updated text color */
}

/* Additional styles from ChatterList.css */
.chatter-list-container {
    background-color: #526D82; /* Updated background */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-height: 100%; /* Ensure it respects the max height of its parent */
    overflow: hidden; /* Prevent content from spilling out */
    width: 100%; /* Ensure it takes up the full width */
}

.chatter-list .chatter-item span {
    font-size: 15px; /* Smaller font size */
    margin-right: 5px; /* Reduce space between name and delete button */
    color: inherit;
}

.chatter-list .btn-sm {
    font-size: 14px;  /* Reduce font size for delete button */
    padding: 5px 5px;  /* Smaller padding for delete button */
}

.chatter-list .chatter-item .d-flex {
    align-items: flex-start; /* Ensure alignment to the top */
    justify-content: flex-start; /* Align content to the left */
    width: 100%; /* Ensure it takes up the full width */
}

.chatter-list-container input[type="text"] {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width calculations */
}

/* Existing styles retained */
.chatter-management-header {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: start;  /* Aligns at the top of the cell */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

/* Chatter generation section in the first column, second row */
.chatter-controls {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

/* Adjusting frequency and tone container at the top */
.settings-section {
    width: 50%;  /* Set width to half of the parent container */
    background-color: #27374d;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

/* Label and button adjustments within the controls */
.chatter-controls label {
    margin-bottom: 10px;
}

.chatter-controls button {
    align-self: flex-start;
    width: 100%;  /* Ensure full width for the button */
}

.chatter-item button {
    background-color: #f4a6a6;
    border: 1px solid #f4a6a6;
    border-radius: 8px;
    padding: 5px;
    width: auto; /* Change from 100% to auto */
    color: white;
    font-weight: bold;
    max-width: 30px; /* Limit the max-width to fit within the item */
    display: inline-block; /* Ensure the button doesn't stretch */
    text-align: center; /* Center the text */
}
