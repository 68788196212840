/* frontend/src/basicChatPage/styles/basicChatMessage.css */
.basic-chat-message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #9DB2BF;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  margin-right: 5px;
}

/* Ensure the message content text is white */
.basic-chat-message .message-content {
  flex-grow: 1;
  margin-left: 2px;
  background-color: #9DB2BF;
  color: #ffffff !important; 
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* White font for display time */
.basic-chat-message .chat-time {
  color: #ffffff !important; /* White text for the display time */
  margin-right: 5px;
}

.basic-chat-message select {
  width: 150px;
  padding: 5px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #000000;  /* Black text by default, overwritten by selectedChatter color */
}

.basic-chat-message select option {
  padding: 5px;
}

.basic-chat-message .form-control {
  border-radius: 6px;
  padding: 8px;
  border: 1px solid #ddd;
}

.basic-chat-message .chatter-buttons {
  display: flex;
  gap: 5px; /* Space between Edit and Delete buttons */
  margin-left: auto; /* Push the buttons to the right */
}

.basic-chat-message .btn-primary {
  background-color: #a3d9a5; /* Greenish background */
  color: #ffffff; /* White text */
  border: none;
  padding: 5px 12px;
  margin-right: 5px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.basic-chat-message .btn-primary:hover {
  background-color: #5da773; /* Darker green for hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Style for the delete button (X) */
.basic-chat-message .delete-button {
  background-color: #fc746d; /* Soft red color */
  color: #ffffff; /* White text */
  border: none;
  padding: 5px 12px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto; /* Push the X button to the right */
}

.basic-chat-message .btn-danger:hover {
  background-color: #e44c4b;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}


.basic-chat-message .time-display:hover {
  background-color: #e0e0e0;
}


.form-control-select {
  min-width: 50px;
  max-width: 150px;  /* Adjust the width as needed */
  padding: 5px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;  /* White background */
  color: #000;  /* Default text color */
  appearance: none;  /* Remove default dropdown arrow */
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="%23000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>'); /* Custom arrow icon */
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 15px;
}

.form-control-select:focus {
  outline: none;  /* Remove focus outline */
  border-color: #4a90e2;  /* Add a highlight border when focused */
}

/* Style options within the dropdown */
.form-control-select option {
  padding: 5px;
  background-color: #fff;
  color: #000;  /* Override to keep default text color */
}

/* Add styles for hiding arrows in number inputs */
.no-arrows {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  display: none;
}

/* Labels for time fields */
.time-edit-container {
  display: flex;
  align-items: center;
  max-width: 150px;
  
}

.time-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

/* Emote image style */
.chat-emote-img {
  width: 24px;
  height: 24px;
  vertical-align: middle; /* Aligns emotes with the text vertically */
  margin: 0 2px; /* Adds some spacing between emotes and text */
}

.editing-section {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: #ffffff;
}

.time-edit-inline {
  display: flex;
  align-items: center;
  gap: 5px;
}

.time-label {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.time-input {
  max-width: 50px; /* Set max width for time inputs */
  padding: 5px;
  margin: 0 5px;
  border-radius: 4px;
  color: #000000;
}

.form-control-select, .form-control {
  border-radius: 6px;
  padding: 5px;
  margin-right: 5px;
}

.btn-primary, .btn-secondary {
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #a3d9a5;
  color: #fff;
  border: none;
}

.btn-secondary {
  background-color: #fc746d;
  color: #fff;
  border: none;
}

.chat-badge-img {
  width: 20px;
  height: 20px;
}

.message-content {
  margin-left: 5px;
  color: #ffffff;
}

.chat-time {
  margin-left: auto;
  color: #ffffff;
}

.input-with-emote {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 360px;
}

.message-display {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between content and delete button */
  gap: 5px;
  width: 100%; /* Ensures the container takes full width */
}

.message-content-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1; /* Takes up the remaining space */
}

.delete-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns delete button to the right */
  margin-left: auto; /* Pushes the delete button to the far right */
}

.delete-button {
  background-color: #fc746d; /* Soft red color */
  color: #ffffff; /* White text */
  border: none;
  padding: 5px 12px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #e44c4b;
}

.emote-picker-modal {
  position: absolute;
  z-index: 3000; /* Ensure it's on top of other elements */
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}