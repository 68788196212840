/*frontend/src/components/common/NavBar.css*/
/* Navbar background and text color */

.navbar {
    background-color: #27374D !important; /* Background color */
    width: 100% !important;
    position: fixed !important; /* Ensures it stays fixed at the top */
    border-bottom : 1px solid #DDE6ED !important; /* Border color */
    z-index: 1000;
    box-shadow: 0 5px 9px rgba(255, 255, 255, 0.2);
}

.navbar-brand, 
.navbar-nav .nav-link {
    color: #DDE6ED !important; /* Text color */
}

/* Navbar text hover effect */
.navbar-brand:hover, 
.navbar-nav .nav-link:hover {
    color: #9DB2BF !important; /* Hover color */
}

/* Navbar toggler (hamburger menu) */
.navbar-toggler {
    border-color: #DDE6ED; /* Border color for the toggler button */
}

.navbar-toggler-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRERFNkVEIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjQiIHk9IjQiLz48cmVjdCB3aWR0aD0iMzIiIGhlaWdodD0iNCIgeT0iMTQiLz48cmVjdCB3aWR0aD0iMzIiIGhlaWdodD0iNCIgeT0iMjQiLz48L3N2Zz4=') !important;
    /* This is a custom white icon for the toggler (hamburger menu), you can customize the color by changing the hex value in the SVG */
}

/* Button styling for navbar links */
.btn-link.nav-link {
    color: #DDE6ED !important; /* Button text color */
}

.btn-link.nav-link:hover {
    color: #9DB2BF !important; /* Hover color for buttons */
}

.container-fluid {
    max-width: 100% !important;
}

.navbar-mrFerret {
    height: 60%;
    width: 60%;
    margin-left: 5px;
    margin: 0;
    padding: 0;
  }

.navbar-logo {
    max-height: 55px;
    width: 70px;
    margin-left: 5px;
    margin: 0;
    padding: 0;
}

.tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-wrapper .tooltip-text {
    visibility: hidden;
    background-color: #b1c5d1;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }
  
  .tooltip-wrapper:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }