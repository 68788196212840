/* frontend/src/basicChatPage/styles/basicChatterAndyManagement.css */
/* frontend/src/basicChatPage/styles/basicChatterAndyManagement.css */

/* Container for the Chatter Andy Management section */

/* Styling for the tabs */
.nav-tabs {
    border-bottom: 1px solid #DDE6ED; /* Light border for the tabs */
}

.nav-tabs .nav-link {
    background-color: #27374D; /* Soft blue background for the tab */
    color: #ffffff; /* White text */
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-tabs .nav-link.active {
    background-color: #DDE6ED; /* Light background for active tab */
    color: #526D82; /* Dark text for active tab */
    border-color: #DDE6ED #DDE6ED #fff; /* Borders for active tab */
}

.nav-tabs .nav-link:hover {
    background-color: #DDE6ED; /* Light background on hover */
    color: #27374D; /* Dark text on hover */
}


.basic-chatter-andy-management {
    background-color: #27374D; /* Soft blue background */
    border-radius: 8px;
    border-top : 1px solid #ffffff;
    border-bottom : 1px solid #ffffff;
    border-left : 1px solid #ffffff;
    border-right : 1px solid #ffffff;
}
