/* frontend/src/components/chatPages/obsOverlay.css */

.overlay-chat-container {
    width: 100%;
    height: 800px;
    background-color: transparent !important;
    color: #ffffff !important;
    overflow-y: none; /* Hide overflow to prevent scrollbars */
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align messages to the bottom */
    padding: 10px;
    font-family: "Roboto", Arial, sans-serif !important;
    font-size: 16px !important;
    font-weight: normal !important;
    border-radius: 5px;
    
}


:root, .overlay-chat-container {
    --bs-body-bg: transparent !important;
} 
.overlay-chat-messages {
    display: flex;
    flex-direction: column; /* Newest messages at the bottom */
    overflow-y: none; /* Prevent scrollbars */
}

.overlay-chat-message {
    margin-top: 4px !important; /* Space between messages */
    margin-left: 2px;
    padding: 5px 0 !important;
    align-items: flex-start;
    background-color: transparent !important;
    overflow-wrap: break-word;
    white-space: normal;
    position: relative; 
}

.overlay-chat-message::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); 
    border-radius: 5px; 
    z-index: -1; 
}


/* Override global styles with specificity */
/* Ensure styles are not affected by global rules */
.overlay-chat-container .overlay-chat-message {
    margin-bottom: 0 !important; /* Remove extra space between messages */
    padding: 2px 0 !important; /* Reduce padding for messages */
    align-items: inline-start; /* Align items horizontally */
    background-color: transparent !important; /* Ensure transparent background */
    overflow-wrap: break-word; /* Break long words to the next line */
    white-space: normal; /* Allow message content to wrap normally */
}

/* Ensure styles are not affected by global rules */
.overlay-chat-container .chat-line {
    align-items: flex-start;
    flex-wrap: wrap; /* Allow wrapping of the content when necessary */
    word-wrap: break-word;
}

/* Override global styles for username */
/* Override global styles for username */
.overlay-chat-container .chat-username {
    font-weight: bold !important; /* Bold the username */
    margin-right: 3px !important; /* Reduced space between username and colon */
    white-space: nowrap !important; /* Prevent username from wrapping */
}

.overlay-chat-container .chat-colon {
    margin-right: 3px !important; /* Slight space between colon and message */
    color: #ffffff !important; /* Fixed black color for colon */
    white-space: nowrap !important; /* Keep the colon on the same line */
}

/* Ensure chat content inherits color */
.overlay-chat-container .chat-content {
    color: inherit !important; /* Ensure the content inherits the color */
    flex-grow: 1;
    word-wrap: break-word; /* Ensure long messages break to the next line */
    font-weight: normal !important; /* Set content font weight to normal */
}
/* Badge and emote image sizes */
.overlay-chat-container .chat-badge-img {
    width: 18px !important; /* Standard Twitch badge size */
    height: 18px !important;
    margin-right: 5px !important; /* Space between badges */
    margin-left: 5px !important; /* Space between badges and username */
    vertical-align: middle !important; /* Align badges vertically with the text */
    object-fit: contain !important; /* Ensure the image doesn't stretch */
}

.overlay-chat-container .chat-content img {
    width: 24px !important; /* Set emote width */
    height: 24px !important; /* Set emote height */
    vertical-align: middle !important; /* Align emotes with text */
    object-fit: contain !important; /* Ensure aspect ratio is maintained */
}

/* Reset potential Bootstrap overrides for all elements inside the overlay */
.overlay-chat-container * {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
}


/* Ensure text stays fully opaque */
.overlay-chat-message .chat-line,
.overlay-chat-message .chat-username,
.overlay-chat-message .chat-colon,
.overlay-chat-message .chat-content {
    position: relative;
    z-index: 1; /* Keeps the text on top of the background */
}