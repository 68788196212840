/* frontend/src/basicChatPage/styles/basicChatEditModal.css */

.basicchat-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1000;
}

.basicchat-modal {
    background-color: #DDE6ED;
    padding: 10px 20px;
    border-radius: 8px;
    width: 800px;
    height: 70px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    z-index: 1500; /* Set modal z-index to be above the overlay */
    position: relative;
}

.basicchat-modal-body {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
}
