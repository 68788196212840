/* src/aboutPage/styles/aboutPage.css */

/* General Layout */
.about-page-container {
  background-color: #141c27; /* Example background color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Sidebar Styling */
.about-sidebar {
  position: fixed;
  top: 59px; /* Aligns with navbar height */
  left: 0;
  width: 240px;
  height: calc(100vh - 58px); /* Full height minus navbar */
  background-color: #27374D;
  border-right: 1px solid #ffffff;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}

.about-sidebar-menu {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.about-sidebar-item {
  color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.about-sidebar-item:hover,
.about-sidebar-item.active {
  background-color: #9DB2BF;
  color: #27374D;
  border-radius: 8px;
}

/* Content Area */
.about-page-content {
  margin-left: 150px; /* Aligns with sidebar width */
  padding: 20px;
  background-color: #141c27;
  flex-grow: 1;
  color: #DDE6ED;
  border-radius: 8px;
  max-width: 1300px;
  
}

/* Section-Specific Styling */
.about-section-container {
  background-color: #27374D;
  margin-top: 60px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 5px 9px rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
}

.about-section-title {
  font-size: 1em;
  color: #DDE6ED;
  text-align: center;
  margin-bottom: 10px;
}

.about-section-content {
  color: #DDE6ED;
  line-height: 1;
}

.about-section-list {
  margin-top: 10px;
  padding-left: 20px;
}

.about-section-list li {
  font-size: 1rem;
  color: #fcdee8;
  margin: 5px 0;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .about-page-container {
      flex-direction: column;
  }
  
  .about-sidebar {
      position: relative;
      width: 100%;
      height: auto;
      transform: translateX(0);
  }

  .about-page-content {
      margin-left: 0;
      width: 100%;
      padding: 20px;
  }

  /* Toggle Button for Sidebar */
  .sidebar-toggle {
      display: block;
      background-color: #27374D;
      color: #ffffff;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.2rem;
  }
}

/* Wide Screen Adjustment */
@media (min-width: 1400px) {
  .about-page-content {
      margin-left: 150px; /* Offset to align left without overlapping sidebar */
  }
}


/* Footer Section */
.about-page-footer {
  background-color: #141c27;
  color: #ffffff;
  text-align: center;
  max-height: 130px;
}