/* src/aboutpage/styles/aboutLive.css */

.about-live-container {
    background-color: #141c27;
    padding: 40px;
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
    color: #DDE6ED;
    line-height: 1.4;
    
}


.about-live-title {
    font-size: 2.2rem;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.about-live-content {
    font-size: 1rem;
    margin-bottom: 30px;
    text-align: justify;
}

.live-section {
    background-color: #27374D;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
}

.section-heading {
    font-size: 1.3rem;
    color: #ffffff;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
}

.about-live-content p,
.about-live-content strong {
    color: #DDE6ED;
}

p {
    margin-bottom: 1rem;
}
