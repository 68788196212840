/* src/aboutpage/styles/aboutDev.css */

.about-dev-container {
    background-color: #141c27;
    padding: 40px;
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
    color: #DDE6ED;
    line-height: 1.4;
  }
  
  .about-dev-title {
    font-size: 2.2rem;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  
  .about-dev-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .dev-card {
    background-color: #27374D;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    width: 100%;
    max-width: 350px;
    text-align: center;
  }
  
  .dev-card-title {
    font-size: 1.3rem;
    color: #ffffff;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
  }
  
  .dev-card p {
    color: #DDE6ED;
    margin: 10px 0;
  }