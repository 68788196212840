/* frontend/src/basicChatPage/styles/basicChatterList.css */


  .basic-chatter-list {
    
    background-color: #27374D;
    
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-height: 700px;
  }

  
  
  /* Heading for the chatter list */
  .basic-chatter-list h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffffff; /* Darker text color */
  }
  
  /* Unordered list of chatters */
  .basic-chatter-list ul {
    list-style: none;
    padding: 0;
    height: 500px;
    margin: 0;
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  /* Each list item for chatters */
  .basic-chatter-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #DDE6ED; /* White background */
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 8px; /* Space between list items */
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect on each chatter item */
  .basic-chatter-list .btn-primary:hover {
    background-color: #5a56e7;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  /* Button for selecting a chatter */
  .basic-chatter-list button {
    background-color: #a3d9a5; /* Soft green background */
    color: #ffffff; /* White text */
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Button hover effect */
  .basic-chatter-list button:hover {
    background-color: #5da773; /* Darker green for hover */
  }
  
  /* Optional: Empty state for when there are no chatters */
  .basic-chatter-list .empty-state {
    font-style: italic;
    color: #888; /* Light gray text for empty state */
    text-align: center;
    margin-top: 20px;
  }

  .basic-chatter-list .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
  }

  .basic-chatter-list .btn-danger {
    background-color: #fc746d; /* Soft red color */
    }

  .basic-chatter-list .btn-danger:hover {
    background-color: #e44c4b;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }


    /* Custom Scrollbar Styles for WebKit Browsers */
    .basic-chatter-list ul::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
  }

  .basic-chatter-list ul::-webkit-scrollbar-thumb {
    background-color: #9DB2BF; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar */
    border: 2px solid #9DB2BF; /* Space around the scrollbar thumb */
  }

  .basic-chatter-list ul::-webkit-scrollbar-track {
    background-color: #526D82; /* Background color of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the scrollbar track */
  }

  /* Scrollbar Styles for Firefox */
  .basic-chatter-list ul {
    scrollbar-width: thin; /* Makes the scrollbar thinner in Firefox */
    scrollbar-color: #9DB2BF #526D82; /* Thumb color and track color for Firefox */
  }

  /* Container for the buttons to align them properly */
  .chatter-buttons-container {
    display: flex;
    justify-content: space-between; /* Aligns buttons across the container */
    align-items: top; /* Vertically center align */
    margin-top: 10px;
    
  }

  /* Optional: Push the Clear Chatters button to the right */
  .chatter-buttons-container .custom-clear-btn {
    
    font-size: 12px;    /* Smaller text size */
    background-color: #fc746d;  /* Red background from btn-danger */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 2px;
    align-items: bottom !important;
  }

  /* Hover effect for the custom button */
  .custom-clear-btn:hover {
    background-color: #e44c4b;  /* Darker red on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);  /* Shadow on hover */
  }

  /* Container for the filter input and clear button */
  .filter-container {
    padding: 5px; /* To contain the absolutely positioned clear button */
    margin-bottom: 10px;
  }

  /* Style the input field */
  .custom-filter-input {
    width: 100%;
    padding-right: 30px; /* Add padding to make space for the clear button */
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 14px;
    box-sizing: border-box; /* Make sure padding is included in the width */
  }

  /* Style the clear button (X) inside the input */
  .clear-filter-btn {
    padding: 2px;
    right: 10px; /* Position it on the right inside the input field */
    top: 50%; /* Vertically center it */
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 18px;
    color: #ffffff !important; /* Red color for the clear button */
    background-color: #fc746d !important;
    cursor: pointer;
    padding: 0;
    line-height: 1;
  }

  .clear-filter-btn:hover {
    color: #e44c4b; /* Change color on hover */
  }

  .chatter-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }