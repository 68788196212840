/* Ensure the body and parent container have proper height */


.popout-chat-container {
    width: 100%;
    min-height: 100%;
    background-color: #ffffff; /* Set the background to white */
    color: #000000; /* Black text color */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
    
    max-height: 730px;
}

.dark-mode .popout-chat-container {
    background-color: #141c27 !important; /* Dark background */
    
}


/* Light mode styles */
.light-mode {
    background-color: #ffffff ; /* White background */
    color: #000000 ; /* Black text */
}

.light-mode .popout-message {
    background-color: transparent ;
    color: #000000 ;
}


/* Dark mode styles */
.dark-mode {
    background-color: #141c27; /* Dark background */
    color: #ffffff ; /* White text */
}

.dark-mode .popout-message {
    background-color: transparent ;
    color: #ffffff ;
    
}

.dark-mode .popout-colon {
    color: #ffffff ;
}

.dark-mode .fake-chat-input-container {
    background-color: #141c27;
    border: 1px solid #ffffff;
}

.dark-mode .fake-chat-input {
    color: #ffffff;
}

.dark-mode .fake-chat-button {
    background-color: #526D82; /* Dark mode button */
}
.dark-mode .chat-bottom-bar {
    background-color: #141c27; /* Dark mode bottom bar */
}

.dark-mode .popout-content {
    color: #ffffff !important; /* Make the message body text white */
}

.dark-mode .icon-settings,
.dark-mode .icon-bits, 
.dark-mode .icon-emotes {
    filter: invert(1) brightness(2); /* Inverts black to white and brightens */
}




.dark-mode .popout-messages-container {
    background-color: #141c27; /* Dark mode message container */
}

.dark-mode .dark-mode{
    background-color: #141c27; /* Dark mode background */
    color: #ffffff; /* White text */
}



/* Container for the messages in the popout */
.popout-messages-container {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0; /* Ensure no padding on messages container */
    margin: 0; /* Ensure no margin */
    box-sizing: border-box;
}

/* Individual message block */
.popout-message {
    padding: 2px 0;
    align-items: flex-start;
    background-color: transparent;
    overflow-wrap: break-word;
    white-space: normal;
    line-height: 20px;
    margin-left: 8px;
}

/* Styling for the username */
.popout-username {
    font-weight: 700;
    margin-right: 3px;
    font-size: 13px;
    line-height: 20px;
    color: #333;
}

/* Colon separating username and message */
.popout-colon {
    color: #333;
    margin-right: 3px;
    font-size: 13px;
}

/* Styling for the message content */
.popout-content {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: #555;
    word-wrap: break-word;
    white-space: normal;
}

/* Styling for badges next to username */
.popout-badge-img {
    width: 17px;
    height: 17px;
    margin-right: 4px;
    vertical-align: middle; /* Ensures badges align vertically with text */
}

/* For Webkit browsers like Chrome, Safari */
html::-webkit-scrollbar, body::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
    background: #ffffff; /* Dark background color for the track */
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    background-color: #ffffff; /* Darker thumb color */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #DDE6ED; /* Border around the thumb for contrast */
}

html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
    background-color: #9DB2BF; /* Lighter color when hovered */
}

html::-webkit-scrollbar-thumb:active, body::-webkit-scrollbar-thumb:active {
    background-color: #DDE6ED; /* Lightest shade when active (clicked) */
}

/* Chat input area styling */
.popout-chat-input {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding: 8px;
    background-color: #f3f3f3;
    margin-left: 8px;
}

/* Fake chat input container */
.fake-chat-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #141c27;
    color: #141c27;
    border-radius: 4px;
    padding: 8px;
    background-color: #ffffff;
    margin-top: 1px;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
}

.fake-chat-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    padding-right: 60px; /* Space for icons */
    background-color: transparent;
    cursor: default;
    
}

/* Icons container inside the input */
.chat-input-icons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px; /* Push icons to the right */
    top: 50%;
    transform: translateY(-50%);
    gap: 8px; /* Space between icons */
    background-color: transparent;
}

.chat-input-icons .icon-bits,
.chat-input-icons .icon-emotes {
    width: 20px;
    height: 20px;
    
    background-color: transparent;
}

/* Optional: Specific styling for icons */
.chat-input-icons .icon-bits {
    background-color: transparent;
    border-radius: 4px;
    width: 16px; /* Adjusted size */
    height: 16px; /* Adjusted size */
    
}

.chat-input-icons .icon-emotes {
    background-color: transparent;
    border-radius: 4px;
    width: 16px; /* Adjusted size */
    height: 16px; /* Adjusted size */
    
}

.chat-input-icons .icon-custom {
    width: 18px;
    height: 18px;
    
}

.icon-twitch-badge {
    width: 18px;
    height: 18px;
    border-radius: 50%; /* Optional: If you want it round */
    object-fit: cover;
    margin-right: 4px; /* Ensures the image covers the area */
}

/* Bottom bar styling */
.chat-bottom-bar {
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    align-items: center;
    margin-top: 5px;
    background-color: white;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom : 8px;
}

.fake-counter {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    margin-right: auto; /* Push counter to the left */
}

.fake-counter img {
    width: 16px; /* Adjusted size for the diamond icon */
    height: 16px; /* Adjusted size for the diamond icon */
    margin-right: 4px;
}
.fake-settings {
    margin-right: 8px;
    cursor: pointer;
}

.icon-settings {
    width: 18px;
    height: 18px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
}

.fake-chat-button {
    background-color: #526D82;
    color: white;
    padding: 7px 12px;
    border-radius: 4px;
    border: none;
    
    font-size: 13px;
    font-weight: 600;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.fake-chat-button:hover {
    background-color: #DDE6ED;
}


.dark-mode .chat-bottom-bar {
    background-color: #141c27 !important; /* Dark mode bottom bar */
}

.dark-mode .popout-message {
    background-color: #141c27;
    color: #ffffff;
}

.fake-chat-component {
    background-color: #ffffff;
}

.dark-mode .fake-chat-component {
    background-color: #141c27;
    color: #ffffff;
}

