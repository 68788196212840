/* General Layout */



.live-page-background {
    background-color: #141c27; /* Ensure this background color is applied */
    height: 450vh;
    padding-bottom: 20px; 
}
.main-content {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    background-color: #141c27 !important; /* Updated background color */
    margin-right: 380px;
    padding-top: 75px;
}

.left-column {
    max-width: 300px; /* Set a fixed width for the left column */
    margin-right: 20px; /* Space between columns */
}

.middle-column {
    flex-grow: 2;
    margin-right: 20px; /* Space between columns */
}

/* Right Column: Chat Panel */
.chat-column {
    position: fixed;
    top: 58px; /* This should be below the Navbar */
    right: 0;
    width: 400px; /* Set the width of the chat panel */
    height: calc(100vh - 60px); /* Full height minus the height of the navbar */
    border-left: #ffffff 1px solid;
    background-color: #27374D; /* Updated background color *//* Updated border color */
    z-index: 999; /* Ensure it's above other content */

    
}




.live-chat-playback::-webkit-scrollbar {
    width: 8px;  /* Width of the scrollbar */
}

.live-chat-playback::-webkit-scrollbar-track {
    background: #526D82;  /* Updated background color for the track */
    border-radius: 10px;  /* Rounded corners */
}

.live-chat-playback::-webkit-scrollbar-thumb {
    background-color: #9DB2BF;  /* Updated thumb color */
    border-radius: 10px;  /* Rounded corners */
    border: 2px solid #526D82;  /* Creates padding around the thumb */
}

.live-chat-playback::-webkit-scrollbar-thumb:hover {
    background-color: #DDE6ED;  /* Lighter color on hover */
}



/* Buttons Section */
.buttons-section {
    display: flex;
    flex-direction: row;   /* Align elements in a row */
    align-items: flex-start; /* Align elements to the start vertically */
    background-color: #9db2bf; 
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-size: 12px;
    border-top: #ffffff 1px solid;
    border-bottom: #ffffff 1px solid;
}

.live-button {
    background-color: #9DB2BF; /* Updated button background color */
    border-color: #9DB2BF;
    border-radius: 8px;
    padding: 10px 15px;
    color: #27374D; /* Updated text color */
    font-weight: bold;
}

.end-button {
    background-color: #fc746d; /* Keep red for end button */
    color: #DDE6ED; /* Light text */
    border-color: #fc746d;
    border-radius: 8px;
    padding: 10px 15px;
    font-weight: bold;
}

.live-popout-button {
    background-color: #526D82;
    color: #ffffff;
    border: none;
    padding: 12px 12px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 20px;
}

.live-popout-button:hover {
    background-color: #e0e0e0;
}

.live-popout-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  /* Align popout button and text to the left */
    margin-right: 10px;       /* Add spacing to the right */
}

.popout-text {
    margin-top: 5px;       /* Space above the text */
    font-size: 14px;       /* Font size for the text */
    color: #FFFFFF;        /* Change color to white */
}
.toggle-container {
    display: flex;
    flex-direction: column;  /* Stack toggles vertically */
    align-items: flex-start; /* Align toggles to the left */
    gap: 10px;               /* Add space between toggles */
}

.toggle-item {
    display: flex;
    align-items: center;     /* Align items vertically */
    justify-content: flex-start; /* Align content to the left */
    gap: 5px;                /* Add space between text and switch */
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

.toggle-item span {
    color: #FFFFFF;        /* Change color to white */
}

/* URL Copy Container */
.url-copy-container {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

/* Button styling */
.copy-url-button, .generate-new-url-button {
    background-color: #526D82; /* Dark blue to match the theme */
    color: #ffffff;
    border: none;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s ease;
}

/* Hover effect */
.copy-url-button:hover, .generate-new-url-button:hover {
    background-color: #6C7A89; /* Slightly lighter blue for hover */
    transform: scale(1.03);
}

/* Active click effect */
.copy-url-button:active, .generate-new-url-button:active {
    background-color: #9DB2BF; /* Even lighter blue for active state */
    transform: scale(0.97);
}

/* Icon styling */
.copy-url-button svg, .generate-new-url-button svg {
    margin-right: 8px; /* Space between icon and text */
}

/* URL Copy Container */
.url-copy-container {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

/* Button styling */
.copy-url-button, .generate-new-url-button {
    background-color: #526D82; /* Dark blue to match the theme */
    color: #ffffff;
    border: none;
    font-size: 0.6rem;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s ease;
    margin-right: 2px;
}

/* Hover effect */
.copy-url-button:hover, .generate-new-url-button:hover {
    background-color: #6C7A89; /* Slightly lighter blue for hover */
    transform: scale(1.03);
}

/* Active click effect */
.copy-url-button:active, .generate-new-url-button:active {
    background-color: #9DB2BF; /* Even lighter blue for active state */
    transform: scale(0.97);
}

/* Icon styling */
.copy-url-button svg, .generate-new-url-button svg {
    margin-right: 8px; /* Space between icon and text */
}