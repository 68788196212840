/* frontend/src/basicChatPage/styles/basicChatSaveManager.css */

.save-manager-container {
    padding: 20px;
    background-color: #27374D; /* Match with BasicChatterList background */
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 600px;
    margin: 0 auto; /* Center the component */
}

/* Title styling */
.save-manager-container h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffffff; /* Darker text color */
}

/* Styling for the saved session list */
.save-manager-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
}

/* Each list item */
.save-manager-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #DDE6ED;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 8px;
    transition: background-color 0.3s ease;
}

/* Hover effect for list items */
.save-manager-item:hover {
    background-color: #f0f0f0;
}

/* Session buttons container */
.save-manager-buttons {
    display: flex;
    gap: 8px;
}

/* Button styling */
.btn-save {
    background-color: #a3d9a5;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-save:hover {
    background-color: #5da773;
}

/* Delete button */
.btn-delete {
    background-color: #fc746d;
}

.btn-delete:hover {
    background-color: #e44c4b;
}

/* Input field */
.save-manager-input {
    width: 100%;
    padding: 8px;
    margin-top: 50px;
    
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Save session button */
.btn-save-session {
    background-color: #27374D;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-save-session:hover {
    background-color: #3C4F65;
    transform: scale(1.02);
}

/* Custom Scrollbar for WebKit Browsers */
.save-manager-list::-webkit-scrollbar {
    width: 8px;
}

.save-manager-list::-webkit-scrollbar-thumb {
    background-color: #9DB2BF;
    border-radius: 10px;
    border: 2px solid #9DB2BF;
}

.save-manager-list::-webkit-scrollbar-track {
    background-color: #526D82;
    border-radius: 10px;
}

/* Scrollbar for Firefox */
.save-manager-list {
    scrollbar-width: thin;
    scrollbar-color: #9DB2BF #526D82;
}

.max-saves-text {
    color: #ffffff; /* Set the text color to white */
    font-size: 14px;
    display: block;
    text-align: center;
    margin-top: 10px;
  }