/* frontend/src/basicChatPage/styles/basicChatControl.css */
.basic-chat-control {
  padding: 5px;
  background-color: #27374D;

  border-top-left-radius: 8px ;
  border-top-right-radius: 8px ;
  border-bottom-right-radius: 0px ;
  border-bottom-left-radius: 0px ;

  border: 1px solid #ffffff;
  
  box-shadow: 0px 1px 3px rgba(240, 95, 95, 0.1);
}

/* Add border-radius and background color around both instances */
.text-box {

  padding: 5px  ;
  border-radius: 15px;
  max-width: fit-content;
  
  
  
}

/* Add border-radius and background color around both instances */
/* Add border-radius and background color around both instances */
.text-box-2 {
  
  padding: 5px;
  border-radius: 15px;
  max-width: fit-content;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  text-align: center; /* Center the text within the box */
}

.basic-chat-control h3 {
  font-weight: bold;
  color: #ffffff; /* Change the "Add Messages" text color */
  
}

.basic-chat-control label {
  
  font-size: 15px;
  
  color: #ffffff; /* Change the label text color */
}
.chat-control-container {
  display: flex;
  
  align-items: center;
}

.message-input-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%; /* Adjust width as needed */
  max-width: 400px;
}

.custom-message-input {
  border-radius: 6px;
  border: 1px solid #DDE6ED;
  width: 100%;
  max-width: 400px;
  
}

.controls-section {
  display: flex;
  flex-direction: column;
  align-items:normal;
  gap: 0px;
  
  width: 100%; /* Adjust width as needed */
}

.increment-control {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  
  border-radius: 8px;

}

.increment-control label {
  margin-right: 10px;
}

.increment-control .btn-outline-primary {
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 14px;
}

.btn-primary {
  background-color: #a3d9a5;
  border-color: #a3d9a5;
  border-radius: 3px;
  padding: 8px 15px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 15px;
}

.btn-primary:hover {
  background-color: #3e8e41;
}

.message-input-section select {
  background-color: #DDE6ED; /* Updated background color */
  border-radius: 6px;
  border: 1px solid #DDE6ED;
  color: #000000; /* Text color */
  padding: 8px;
}

.custom-message-input {
  background-color: #DDE6ED; /* Updated background color */
  border-radius: 6px;
  border: 1px solid #DDE6ED;
  width: 100%;
  max-width: 400px;
  color: #000000; /* Text color */
  padding: 8px;
}

.increment-control .btn-custom-time {
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 14px;
  background-color: #27374d; /* Unique background color */
  border: 2px solid #93E7C0; /* Border with matching color */
  color: white;
  transition: background-color 0.3s ease;
}

.increment-control .btn-custom-time:hover {
  background-color: #27374d; /* Darken on hover */
  color: white;
}

.increment-control .btn-custom-time.active {
  background-color: #93E7C0; /* Make sure this color is distinct when active */
  color: white;
}

.emote-button {
  position: absolute;
  right: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
}

.input-with-emote {
  position: relative;
  display: flex;
  align-items: center;
}