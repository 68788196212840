.homepage-container {
  background-color: #27374d;
  color: #DDE6ED;
  overflow-x: hidden;
}

.home-page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
}

/* Left and Right GIF positioning */
.left-gif {
  position: absolute;
  left: calc(50% - 980px);
  top: 150px; /* Adjust for positioning to the left of the header */
  width: 550px;
  height: 550px;
  border-radius: 50px;
  border: #ffffff solid 5px;
  box-shadow: 0px 0px 100px 5px #9db2bf! important;

}

.no-scroll {
  overflow-x: hidden;
}


.right-gif {
  position: absolute;
  right: calc(50% - 1000px);
  top: 175px;
  width: 600px;
  height: 423px;
  border-radius: 50px;
  border: #ffffff solid 5px;
  box-shadow: 0px 0px 100px 5px #9db2bf! important;
}

/* Header Section */
.header-section {
  margin-top: 20px;
  background-color: transparent;
  padding: 20px 0;
  position: relative;
  z-index: 1; /* Ensure header is above the GIFs */
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

.header-text {
  text-align: center;
}

.header-title {
  font-size: 3rem;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 10px;
  max-width: 95%;
  
}


.header-title strong {
  color: #fcdee8;
  
}

.header-description {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.6;
  max-width: 95%;
  margin-top: 100px;
}

/* Carousel Section */
.carousel-section {
  margin-top: 100px;
  padding: 40px 0;
  background-color: #141c27;
  width: 100vw; /* Full viewport width */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 800px;
  
  
}

.carousel-hidden {
  color: #27374D;
  font-size: .5rem;

  background-color: #27374D;
}

.carousel-container {
  max-width: 1500px;
  margin: 0 auto;
  justify-content: center;
  box-shadow: 0px 250px 200px 5px #27374D! important;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 30px 5px #ffffff57! important;
  border-radius: 8px;
}
.carousel-title {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fcdee8;
}

.carousel-description {
  text-align: center;
  color: #DDE6ED;
}

.carousel-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.carousel-image {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  border-radius: 10px;

  
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: brightness(10);
  width: 1400;
  size: 200%;
  background-color: #000000;
  border-radius: 8px;
}

/* Services Section */
/* Services Section */
.services-section {
  padding: 40px 0;
  background-color: #b1c5d1;
  width: 100vw; /* Full viewport width */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.services-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.services-title {
  font-size: 2rem;
  font-weight: bold;
  color: #27374D;
  margin-bottom: 20px;
}

.services-cards {
  display: flex;
  flex-wrap: none;
  gap: 20px;
  justify-content: center;
}

.service-card {
  background-color: #27374D;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff;
  width: 100%;
  max-width: 350px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-card-title {
  font-size: 1.3rem;
  color: #ffffff;
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 5px;
}

.service-card-description {
  color: #DDE6ED;
  margin: 10px 0;
}

.service-card-description-price {
  color: #fdd9e1;
  font-size: 1.5rem;
  margin: 10px 0;

}

.service-card-description strong {
  color: #ffb0c1;
  
}

.services-description-price strong {
  color: #c53e5b;
  
}

.click-here-homepage-subscribe
{
  border-radius: 8px;
  background-color:#c53e5b;
  color: #ffffff;
}

.click-here-homepage-subscribe :hover
{
  border-radius: 8px;
  background-color:#c53e5b;
  color: #ffffff;
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.5);
}
/* Contact Section */
.contact-section {
  padding: 40px 0;
  background-color: #141c27;
  color: #DDE6ED;
  width: 100vw; /* Full viewport width */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-title {
  font-size: 2rem;
  font-weight: bold;
  color: #FFFFFF;
}

.contact-description {
  color: #DDE6ED;
}



.contact-email {
  font-weight: bold;
  font-size: 1.2rem;
  color: #f77d97;
}
