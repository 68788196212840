/* Chatter Settings Styles */
.chatter-settings-container {
    background-color: #27374D;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    color: #DDE6ED;
}

.chatter-settings-container label {
    color: #DDE6ED;
    font-weight: bold;
}

.badge-dropdown {
    margin-top: 10px;
}

.selected-badges {
    display: flex !important;
    align-items: left !important;
    margin-right: 10px !important; /* Space between badges and input field */
}

/* Badge icon next to the username */
.badge-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px; /* Space between individual badges */
    vertical-align: middle;
}
/* Button Styling */
.btn-primary {
    background-color: #526D82;
    border-color: #526D82;
}

.btn-primary:hover {
    background-color: #27374D;
    border-color: #27374D;
}

.btn-secondary {
    background-color: #526D82;
    border-color: #526D82;
}

.btn-secondary:hover {
    background-color: #27374D;
    border-color: #27374D;
}

/* Color Box for Username Color Picker */
.color-box {
    width: 24px;
    height: 24px;
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    margin-right: 10px;
}

/* Color Picker Modal */
.color-picker-modal {
    position: absolute;
    z-index: 1000; /* Ensure it's on top */
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Color Selection Grid */
.color-selection-grid {
    margin-top: 1px;
    border: 1px solid #DDE6ED;
}

.color-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.color-square {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    border: 1px solid #DDE6ED;
}

.color-square:hover {
    transform: scale(1.1);
}

.badge-groiup {
    border: 1px solid #DDE6ED;

}

/* Username Input and Badges Row */
.input-row {
    display: flex;
    align-items: left !important;
    margin-bottom: 15px;
}

.username-input-container {
    width: 100%;
    display: flex;
    align-items: left !important;
    
}