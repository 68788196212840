/* frontend/src/basicChatPage/styles/basicChatPlayback.css */

.basicchat-playback-container {
    width: 100%;
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-left: 1px solid #ffffff; 
    
}

/* Light mode styles */
.light-mode .basicchat-playback-container {
    background-color: #ffffff !important;
    
}

/* Dark mode styles */
.dark-mode .basicchat-playback-container {
    background-color: #141c27 !important;
    
}

/* Controls container */
.controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    background-color: #9db2bf;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff
}

.dark-mode .controls-container {
    background-color: #9db2bf !important;
}

/* Playback controls */
.basicchat-playback-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 5px 0;
    user-select: none;
    margin-left: 8px;
    margin-right: 8px;
}

.dark-mode .basicchat-playback-controls {
    background-color: #141c27 !important;
    color: #ffffff !important;
}

/* Messages container */
.basicchat-messages-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow-y: auto;
    margin-left: 10px;
}

.light-mode .basicchat-messages-container {
    background-color: #ffffff !important;
}

.dark-mode .basicchat-messages-container {
    background-color: #141c27 !important;
}

/* Messages styling */
.basicchat-message {
    margin-bottom: 0 !important;
    padding: 2px 0 !important;
    align-items: inline-start;
    background-color: #ffffff !important;
    overflow-wrap: break-word;
    white-space: normal;
    line-height: 20px;
}

.light-mode .basicchat-message {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.dark-mode .basicchat-message {
    background-color: #141c27 !important;
    color: #ffffff !important;
}

/* Username */
.basicchat-username {
    font-weight: 700 !important;
    margin-right: 1px !important;
    font-size: 13px !important;
    line-height: 20px;
}



/* Colon between username and message */
.basicchat-colon {
    color: #000000 !important;
    margin-right: 3px !important;
    font-size: 13px !important;
}

.dark-mode .basicchat-colon {
    color: #ffffff !important;
}

/* Message content */
.basicchat-content {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 20px;
    text-decoration: none solid rgb(14, 14, 16);
    text-align: start;
    text-indent: 0px;
    text-transform: none;
    vertical-align: baseline;
    white-space: normal;
    word-spacing: 0px;
}

/* Scrollbar styling */
.basicchat-messages-container::-webkit-scrollbar {
    width: 8px;
}

.basicchat-messages-container::-webkit-scrollbar-track {
    background: #ffffff !important;
    border-radius: 10px !important;
}

.basicchat-messages-container::-webkit-scrollbar-thumb {
    background-color: #555 !important;
    border-radius: 10px !important;
    border: 2px solid #ffffff !important;
}

.basicchat-messages-container::-webkit-scrollbar-thumb:hover {
    background-color: #777 !important;
}

/* Timeline container */
.basicchat-timeline-container {
    width: 100%;
    height: 10px;
    background-color: #2B4D5F;
    position: relative;
    cursor: pointer;
    margin-left: 0px;
    
}

.dark-mode .basicchat-timeline-container {
    background-color: #9DB2BF !important;
}

/* Timeline bar */
.basicchat-timeline-bar {
    height: 100%;
    background-color: #9DB2BF;
    border-radius: 5px;
    
}

.dark-mode .basicchat-timeline-bar {
    background-color: #ffffff !important;
}

.dark-mode .basicchat-timeline-handle {
    background-color: #ffffff !important;
}

/* Current time */
.basicchat-current-time {
    margin-top: 5px;
    font-size: 16px;
    color: #526D82;
}

.dark-mode .basicchat-current-time {
    color: #ffffff !important;
}

/* Buttons styling */
.basicchat-playback-controls button {
    background-color: #526D82;
    color: #ffffff;

    padding: 5px 5px;
    font-size: 12px;
    cursor: pointer;
    margin: 0 10px;
}

.basicchat-playback-controls button:hover {
    background-color: #415868;
}

/* Popout button */
.basicchat-popout-button {
    background-color: #526d82;
    color: #ffffff;
    border: none;

    padding: 8px 12px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 20px;
}

.basicchat-popout-button:hover {
    background-color: #415868;
}

/* Badge image */
.chat-badge-img {
    width: 17px;
    height: 17px;
    margin-right: 4px;
    vertical-align: middle;
    font-size: 13px;
    line-height: 20px;
    word-spacing: 0px;
    display: inline;
    overflow: clip;
    
    box-sizing: border-box;
}

/* Slider toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 17px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #526D82;
}

input:checked + .slider:before {
    transform: translateX(13px);
}

.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Dark mode toggle container */
.dark-mode-toggle-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-right: 10px;
}

.dark-mode-label {
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    padding-right: 10px;
}

.dark-mode .dark-mode-label {
    color: #ffffff;
}

/* Playback buttons container */
.basicchat-playback-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
  }

.basicchat-current-time {
    font-size: 16px;
    color: #526D82;
    margin-left: auto;
    padding-left: 10px;
}

.basicchat-playback-buttons button {
    background-color: #526D82;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }



.basicchat-current-time {
    font-size: 16px;
    color: #526D82;
    margin-left: auto; /* Pushes the current time to the right */
    padding-left: 10px; /* Optional: adds space between buttons and time */
}

.basicchat-playback-components {
    border-left: 5px solid #27374d;
}

.fake-chat-display-div-create {
    border-left: 1px solid #ffffff;

}

.TimeBar-Container-Create{
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff
}