/* frontend/src/components/common/fakeChatDisplay.css */

/* Ensure the body and parent container have proper height */
html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}




/* Dark mode for input container */
.dark-mode .fake-chat-input-container {
    background-color: #141c27;
    border: 1px solid #ffffff; /* White border in dark mode */
}

/* Fake chat input text color in dark mode */
.dark-mode .fake-chat-input {
    color: #ffffff !important;
}

/* Dark mode button */
.dark-mode .fake-chat-button {
    background-color: #526D82; /* Button background in dark mode */
}

.dark-mode .chat-bottom-bar {
    background-color: #141c27; /* Dark mode background for bottom bar */
}

/* Dark mode content color */
.dark-mode .popout-content {
    color: #ffffff !important; /* White text for message body in dark mode */
}

/* Icon styles for dark mode */
.dark-mode .icon-settings,
.dark-mode .icon-bits,
.dark-mode .icon-emotes {
    filter: invert(1) brightness(2); /* Invert and brighten icons in dark mode */
}

/* Dark mode message container */
.dark-mode .popout-messages-container {
    background-color: #141c27; /* Dark mode background for message container */
}


/* Fake chat input container */
.fake-chat-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #141c27;
    color: #27374D;
    border-radius: 4px;
    padding: 8px;
    background-color: #ffffff; /* Light mode background for input container */
    margin-top: 1px;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
}

/* Fake chat input */
.fake-chat-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    padding-right: 60px; /* Space for icons */
    background-color: #ffffff;
    cursor: default;
}

/* Icons container inside input */
.chat-input-icons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px; /* Push icons to the right */
    top: 50%;
    transform: translateY(-50%);
    gap: 8px; /* Space between icons */
    background-color: #ffffff;
}

.chat-input-icons .icon-bits,
.chat-input-icons .icon-emotes {
    width: 20px;
    height: 20px;
    background-color: #ffffff;
}

/* Specific styling for icons */
.chat-input-icons .icon-bits {
    background-color: #ffffff;
    border-radius: 4px;
    width: 16px;
    height: 16px;
}

.chat-input-icons .icon-emotes {
    background-color: #ffffff;
    border-radius: 4px;
    width: 16px;
    height: 16px;
}

/* Icon image */
.icon-twitch-badge {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 4px;
}

/* Bottom bar styling */
.chat-bottom-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    background-color: #ffffff;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
}

/* Fake counter */
.fake-counter {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    margin-right: auto; /* Push counter to the left */
}

/* Diamond icon in counter */
.fake-counter img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

/* Settings icon */
.fake-settings {
    margin-right: 8px;
    cursor: pointer;
}

/* Icon styling */
.icon-settings {
    width: 18px;
    height: 18px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    background-color: transparent;
}

/* Chat button styling */
.fake-chat-button {
    background-color: #526D82;
    color: white;
    padding: 7px 12px;
    border-radius: 4px;
    border: none;
    font-size: 13px;
    font-weight: 600;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.fake-chat-button:hover {
    background-color: #DDE6ED;
}

/* Dark mode for the fake chat component */
.dark-mode .fake-chat-component {
    background-color: #141c27;
    color: #ffffff !important;
}
