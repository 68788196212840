/* src/containers/LoginPage.css */

.login-wrapper-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #526d82;
  }
  
  .container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
  }
  
  .row-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150%;
  }
  
  .col-login {
    background-color: #27374D;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #DDE6ED;
    border: 1px solid #ffffff;
    width: 150%;
    padding: 30px;
  }
  
  .card-login {
    background: none;
    border: none;
  }
  
  .card-body-login {
    padding: 20px;
  }
  
  .card-title-login {
    color: #DDE6ED;
    text-align: center;
    font-size: 1.2rem;
    
    border-bottom: 1px solid #DDE6ED;
    
  }
  
  .login-button-login {
    background-color: #9db2bf;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    padding: 20px 0;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-top: 20px;
  }
  
  .login-button-login:hover {
    background-color: #005BB5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  