/* src/aboutPage/styles/aboutMain.css */
/* src/aboutpage/styles/aboutMain.css */

.about-main-container {
    background-color: #141c27;
    padding: 40px;
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
    color: #f8f8f8;
    line-height: 1.3;
  }
  
  .about-main-title {
    font-size: 2.2rem;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  
  .about-main-intro {
    font-size: 1rem;
    margin-bottom: 30px;
    text-align: center;
    justify-content: center;
  }
  
  .about-main-sections {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
  }
  
  .about-main-section {
    background-color: #141c27;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 9px rgba(255, 255, 255, 0.2);
    border: 1px solid #ffffff;
  }
  
  .section-heading {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 15px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
    
  }
  
  .feature-section {
    margin-top: 15px;
    
  }
  
  .feature-heading {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;
  }
  
  .benefit-list,
  .feature-list,
  .future-plans-list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
  .benefit-list li,
  .feature-list li,
  .future-plans-list li {
    padding: 8px 0;
    font-size: 1rem;
  }
  
  .about-main-intro strong{
    color: #f77d97;
  }
  .benefit-list li strong,
  .feature-list li strong {
    color: #fc889b;
  }
  
  .future-plans-list li {
    color: #ffffff;
  }
  
  p {
    margin-bottom: 1rem;
  }
  
  .about-main-container p,
  .about-main-container ul {
    text-align: left;
  }
  