/* src/containers/components/PrivacyPolicy.css */

.full-background {
    background-color: #141c27;
    min-height: 100vh; /* Ensure it covers the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.privacy-policy-container {
    background-color: #141c27;
    padding: 40px;
    border-radius: 8px;
    max-width: 1200px;
    margin: auto;
    color: #DDE6ED;
    line-height: 1.3;
  }
  
  .privacy-policy-title {
    font-size: 2.2rem;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content {
    font-size: 1rem;
    margin-bottom: 30px;
    text-align: justify;
  }
  
  .privacy-section {
    background-color: #27374D;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    max-width: 100%;
  }
  
  .privacy-heading {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 10px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
  }
  
  .privacy-subheading {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .privacy-policy-content p,
  .privacy-policy-content a {
    color: #DDE6ED;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-content a:hover {
    color: #9DB2BF;
    text-decoration: underline;
  }
  
  p {
    margin-bottom: 1rem;
  }