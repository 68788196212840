/* src/basicChatPage/styles/basicChatGeneration.css */

.basic-chat-generation {
    padding: 5px;
    background-color: #27374D;
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 3px rgba(240, 95, 95, 0.1);
}

.basic-chat-generation-title {
    font-weight: bold;
    color: #ffffff;
}

.basic-chat-generation-label {
    font-size: 15px;
    color: #ffffff;
}

.basic-chat-generation-input-group {
    margin-bottom: 5px;
}
.token-value-basic {
    background-color: #DDE6ED;
    border-radius: 6px;
    border: 1px solid #DDE6ED;
    color: #000000;
    width: 100%;
    max-width: 100px;
}

.basic-chat-generation-input {
    background-color: #DDE6ED;
    border-radius: 6px;
    border: 1px solid #DDE6ED;
    color: #000000;
    
    width: 600px;
    max-width: 400px;
}

.basic-chat-generation-input-time {
    background-color: #DDE6ED;
    border-radius: 6px;
    border: 1px solid #DDE6ED;
    color: #000000;
   
    width: 100%;
    max-width: 50px;
}

.basic-chat-generation-message-rate {
    background-color: #DDE6ED;
    border-radius: 6px;
    border: 1px solid #DDE6ED;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    max-width: 60px;
}

.basic-chat-generation-input-context {
    background-color: #DDE6ED;
    border-radius: 6px;
    border: 1px solid #DDE6ED;
    color: #000000;
    width: 100%;
    max-width: 500px;
    height: 100px;
}


.basic-chat-generation-input-name {
    background-color: #DDE6ED;
    border-radius: 6px;
    border: 1px solid #DDE6ED;
    color: #000000;
    font-weight: bold;
    padding: 8px;
    width: 100%;
    max-width: 200px;
}

.basic-chat-generation-event-timings {
    margin-top: 2px;
    background-color: #526D82;
    border-radius: 5px;

}

.basic-chat-generation-subtitle {
    font-weight: bold;
    color: #ffffff;
}

.basic-chat-generation-event {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.message-display,
.editing-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    padding: 5px;
    background-color: #9DB2BF;
    border-radius: 6px;
    border: 1px solid #ddd;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.message-content-wrapper-generate {
    align-items:normal;
    display: flex;
    gap: 5px;
    flex-grow: 1;
}

.chat-time {
    color: #ffffff;
    margin-right: auto; /* Aligns the time to the left */
    margin-left: 10px;
}
.message-content {
    color: #ffffff;
    margin-left: 20px;
    justify-content: center;
}

.btn-primary, .btn-danger {
    padding: 5px 10px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
    transition: background-color 0.3s ease;
}

.btn-primary {
    background-color: #a3d9a5;
    color: #ffffff;
    border: none;
}

.btn-primary:hover {
    background-color: #5da773;
}

.btn-danger {
    background-color: #fc746d;
    color: #ffffff;
    border: none;
}

.btn-danger:hover {
    background-color: #e44c4b;
}

.basic-chat-generation-token-estimate,
.basic-chat-generation-response,
.basic-chat-generation-error {
    margin-top: 20px;
}

.basic-chat-generation-token-estimate p,
.basic-chat-generation-response pre,
.basic-chat-generation-error pre {
    background-color: #526D82;
    padding: 10px;
    border-radius: 8px;
    color: #ffffff;
}

.basic-chat-generation-buttons {
    background-color: #526D82;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.basic-chat-generation-bottom-buttons {
    background-color: #27374d;
    padding: 10px;

    color: #ffffff;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}