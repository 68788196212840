/* src/components/LivePage/LiveAISettings.css */

/* General container styling */
.ai-settings-container {
    background-color: #27374D;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff;
}

/* Token info box */
.token-info {
    background-color: #526D82;
    padding: 10px;
    border-radius: 8px;
}

.token-info p {
    margin: 0;
    font-size: 0.9rem;
}

.token-value {
    font-weight: bold;
    color: #DDE6ED;
}

/* Tabs styling */
.tabs {
    display: flex;
    gap: 10px;
}

.tab {
    background-color: #526D82;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: bold;
    border: 1px solid transparent;
}

.tab.active {
    background-color: #946bb1;
    border-color: #ffffff;
}

.tab:hover:not(.disabled-tab) {
    background-color: #6C7A89;
}

.disabled-tab {
    color: #9DB2BF;
    cursor: not-allowed;
    position: relative;
}

/* Tooltip styling for disabled tabs */
.disabled-tab[data-tooltip]:hover:after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #DDE6ED;
    color: #27374D;
    padding: 5px;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

/* General styling for settings content */
.settings-section {
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

label {
    font-size: 0.9rem;
    margin-bottom: 5px;
}

input[type="range"] {
    width: 100%;
    margin-bottom: 5px;
    appearance: none;
    height: 4px;
    background: #526D82;
    outline: none;
    opacity: 0.9;
}

input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    background: #946bb1;
    cursor: pointer;
    border-radius: 50%;
}

.settings-description {
    font-size: 0.8rem;
    color: #9DB2BF;
    margin-top: 5px;
}

/* Tone and Mode button styling */
.tone-buttons, .mode-buttons {
    display: flex;
    gap: 10px;
}

.tone-btn, .mode-btn {
    flex: 1;
    background-color: #526D82;
    color: #ffffff;
    border: 2px solid #526D82;
    padding: 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
}

.tone-btn.selected, .mode-btn.selected {
    background-color: #946bb1;
    border-color: #ffffff;
}

.tone-btn:hover, .mode-btn:hover {
    background-color: #6C7A89;
    border-color: #6C7A89;
}

.tone-btn svg, .mode-btn svg {
    margin-right: 5px;
}
