/* src/styles/ChatterList.css */

/* Adjust the chatter list to be a single column */
.chatter-list {
    
    overflow-y: auto; /* Enable vertical scrolling */
    width: 100%; /* Ensure it stays within its parent container */
    padding: 5px; /* Optional: Add some padding */
    box-sizing: border-box; /* Ensure padding is included in width calculations */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Add a small gap between items */
    background-color: #9DB2BF !important;
    border-radius: 5px !important;
  }
  
  .chatter-list-container {
    min-height: 400px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-height: 100%; /* Ensure it respects the max height of its parent */
    overflow: hidden; /* Prevent content from spilling out */
    width: 100%; /* Ensure it takes up the full width */
    border : 1px solid #ffffff;
  }
  
  /* Ensure background color reverts when not hovering */
  .chatter-list .chatter-item {
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    background-color: #e8f4ff; /* Set initial background color */
    padding: 5px 10px; /* Adjust padding for smaller size */
    cursor: pointer; /* Indicate clickable */
    border-bottom: 1px solid #ddd; /* Separator between items */
    max-width: 100%; /* Ensure items take up the full width */
    color: inherit; /* Allow color to be inherited from inline styles */
    justify-content: space-between; /* Aligns the content inside the chatter-item */
    align-items: center; /* Vertically centers the content */
  }
  
  /* Background color change on hover */
  .chatter-list .chatter-item:hover {
    background-color: #f0f0f0; /* Light gray background on hover */
  }
  
  .chatter-list .chatter-item span {
    font-size: 15px; /* Smaller font size */
    margin-right: 5px; /* Reduce space between name and delete button */
    color: inherit;
  }
  
  .chatter-list .btn-sm {
    font-size: 14px;  /* Reduce font size for delete button */
    padding: 5px 5px;  /* Smaller padding for delete button */
  }
  
  .chatter-list .chatter-item .d-flex {
    align-items: flex-start; /* Ensure alignment to the top */
    justify-content: flex-start; /* Align content to the left */
    width: 100%; /* Ensure it takes up the full width */
  }
  
  /* Style for the filter input */
  .chatter-list-container input[type="text"] {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width calculations */
  }
  
  .chatter-item button {
    background-color: #f4a6a6;
    border: 1px solid #f4a6a6;
    border-radius: 8px;
    padding: 5px;
    width: auto;
    color: white;
    font-weight: bold;
    max-width: 30px;
    display: inline-block;
    text-align: center;
    margin-left: auto; /* Pushes the button to the right */
    display: flex;
    align-items: center; /* Vertically centers the content inside the button */
    justify-content: center; /* Horizontally centers the content inside the button */
}

/* Style for the "Create Chatter Andy" button */
.chatter-list-container .btn-primary {
  background-color: #93e7c0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  width: 100%; /* Make the button take the full width */
  margin-top: 10px; /* Add space above the button */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatter-list-container .btn-primary:hover {
  background-color: #0056b3; /* Darken the button on hover */
}

/* Custom scrollbar styles */
.chatter-list::-webkit-scrollbar {
  width: 8px;  /* Width of the scrollbar */
  border-radius: 8px;
}

.chatter-list::-webkit-scrollbar-track {
  background: #ffffff;  /* Light background color for the scrollbar track */
  border-radius: 15px;
}

.chatter-list::-webkit-scrollbar-thumb {
  background-color: #DDE6ED;  /* Darker color for the scrollbar */
  border-radius: 10px;  /* Rounded corners */
}

.chatter-list::-webkit-scrollbar-thumb:hover {
  background-color: #9DB2BF;  /* Darker color on hover */
}
/* Styling for context list */
.context-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1px;
}

/* Each list item in the context list */
.context-list li {
  background-color: #9DB2BF; /* Set the background color */
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff; /* Darker text color for readability */
}

/* Add hover effect to the list items */
.context-list li:hover {
  background-color: #8AA0A8; /* Slightly darker shade on hover */
}

/* Button styling inside context list */
.context-list li .btn {
  padding: 5px 5px;
  font-size: 0.9rem;
  border-radius: 5px;
}