/* frontend/src/basicChatPage/styles/basicChatEmotePicker.css */
.emote-picker {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensure it appears above other elements */
    max-width: 300px;
    max-height: 300px;
  }
  
  .emote-icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  

  