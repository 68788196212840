/* src/containers/components/ContactSections.css */
.contact-section-custom {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transform: translateX(-50vw); /* Center the section horizontally */
    position: relative;
    left: 50%;
    right: 50%;
    border-top: 1px solid #ffffff; /* Add border on top of the section */
    background-color: #141c27;
}

.contact-logo-custom {
    width: 50px; /* Adjust the size as needed */
    height: auto;
}

.contact-info-custom {
    display: flex;
    flex-direction: row; /* Ensure the boxes are side by side */
    align-items: flex-start;
}

.logo-wrapper-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    margin-left: 10px;
    margin-right: 10px;
}

.contact-box-custom {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 300px;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
}

.contact-title-custom {
    margin: 0;
    border-bottom: 1px solid #ffffff; /* Add border underneath the heading */
    width: 100%;
    text-align: left;
}

.contact-box-content-custom {
    text-align: left;
    margin-top: 5px;
}

.contact-box-content-custom p,
.contact-box-content-custom a {
    margin: 5px 0;
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit color from parent */
}

.contact-box-content-custom a:hover {
    color: 
    #9db2bf; /* Change link color to white on hover */
}