/* src/accountPage/styles/accountPage.css */
/* General Layout */
.account-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #141c27; /* Example background color */
  overflow: hidden; /* Disable vertical scrolling */
}

.account-page-content-wrapper {
  display: flex;
  flex: 1;
}

/* Sidebar Styling */
.account-sidebar {
  position: fixed;
  top: 59px; /* Aligns with navbar height */
  left: 0;
  width: 240px; /* Consistent width with live page */
  height: calc(100vh - 58px); /* Full height minus navbar */
  background-color: #27374D;
  border-right: 1px solid #ffffff;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sidebar-menu {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.sidebar-item {
  color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.sidebar-item:hover,
.sidebar-item.active {
  background-color: #9DB2BF;
  color: #27374D;
  border-radius: 8px;
}

/* Main Content Area */
.account-page-content {
  margin-left: 260px; /* Align with sidebar width */
  width: 50%;
  padding: 20px;
  background-color: transparent;
  flex-grow: 1;
  color: #DDE6ED;
  border-radius: 8px;
  overflow: hidden; 
}

/* Footer Section */
.account-page-footer {
  margin-top: auto;
  background-color: #141c27;
  border: 1px solid #27374D;
  color: #ffffff;
  text-align: center;
  height: 130px;
}

/* Consistent Title & Info Section */
.account-page-title {
  text-align: center;
  margin-bottom: 20px;
}

.account-info-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.account-info-item {
  background-color: #9DB2BF;
  padding: 15px;
  border-radius: 5px;
  color: #27374D;
  transition: background 0.3s;
}

.account-info-item:hover {
  background-color: #DDE6ED;
  color: #526D82;
}

.account-info-item h2 {
  margin: 0 0 10px;
}

.account-info-item p {
  font-size: 16px;
  font-weight: bold;
}