/* frontend/src/components/LivePage/LivePlaybackComponent.css */
.live-chat-playback {
    width: 100%;
    height: 92.2%;
    color: #000000 !important; /* Black text for light theme */
    display: flex;
    flex-direction: column;
    
    font-family: "Roboto", Arial, sans-serif !important; /* Match Twitch font */
    font-size: 16px !important; /* Keep the size you like */
    font-weight: normal !important; /* Set default font weight */
    justify-content: flex-end;
    padding: 0;  /* Ensure no padding on container */
    margin: 0; /* Ensure no margin */
    box-sizing: border-box; /* Prevent overflow from padding/margin */
    
}

.live-chat-messages {
    height: 100%; /* Ensure it uses the available height */
    max-height: 750px; /* Set a max height */
    overflow-y: auto; /* Enable scrolling */
    display: flex;
    flex-direction: column-reverse;
    font-size: 80%;
    padding: 0; /* Ensure no padding on messages container */
    margin: 0; /* Ensure no margin */
    box-sizing: border-box; /* Prevent overflow from padding/margin */
    
}

.live-chat-message {
    margin-bottom: 0 !important; 
    padding: 3px 0 !important; 
    align-items: inline-start; 
    background-color: #ffffff !important; 
    overflow-wrap: break-word; 
    margin-left: 11px;
    white-space: normal; 
}

/* Bold username while keeping the rest normal */
.live-chat-message .chat-username {
    font-weight: bold !important; /* Bold the username */
    margin-right: 3px !important; /* Reduced space between username and colon */
    color: inherit 
}

.live-chat-message .chat-colon {
    color: #000000 !important; /* Fixed black color for colon */
    margin-right: 3px !important; /* Slight space between colon and message */
}

.live-chat-message .chat-content {
    color: #000000 !important; /* Ensure the content inherits the color */
    flex-grow: 1;
    word-wrap: break-word; /* Ensure long messages break to the next line */
    font-weight: normal !important; /* Set content font weight to normal */
}

/* Badge and emote image sizes */
.chat-badge-img {
    width: 18px !important;  /* Standard Twitch badge size */
    height: 18px !important;
    margin-right: 5px !important;  /* Space between badges */
    vertical-align: middle !important;  /* Align badges vertically with the text */
    object-fit: contain !important;  /* Ensure the image doesn't stretch */
}

/* Ensure emote images are resized properly */
.chat-content img {
    width: 24px !important;  /* Set emote width */
    height: 24px !important;  /* Set emote height */
    vertical-align: middle !important;  /* Align emotes with text */
    object-fit: contain !important;  /* Ensure aspect ratio is maintained */
}


/* Custom scrollbar styles */
.live-chat-messages::-webkit-scrollbar {
    width: 8px;  /* Width of the scrollbar */
}

.live-chat-messages::-webkit-scrollbar-track {
    background: #ffffff;  /* Light background color for the scrollbar track */
}

.live-chat-messages::-webkit-scrollbar-thumb {
    background-color: #DDE6ED;  /* Darker color for the scrollbar */
    border-radius: 10px;  /* Rounded corners */
}

.live-chat-messages::-webkit-scrollbar-thumb:hover {
    background-color: #9DB2BF;  /* Darker color on hover */
}


/* Timestamp styles */
.chat-timestamp {
    color: #808080; /* Grey color for the timestamp */
    margin-right: 10px; /* Padding to the right of the timestamp */
    font-size: 12px; /* Smaller font size for the timestamp */
}



/* Dark mode styles */
.dark-mode .live-chat-playback {
    background-color: #141c27 !important; /* Dark background */
    color: #ffffff !important; /* White text */
}

.dark-mode .live-chat-message {
    background-color: #141c27 !important; /* Dark message background */
    color: #ffffff !important; /* White text */
}

.dark-mode .live-chat-message .chat-colon {
    color: #ffffff !important; /* White colon */
}

.dark-mode .live-chat-message .chat-content {
    color: #ffffff !important; /* White message content */
}

.dark-mode .live-chat-messages::-webkit-scrollbar-track {
    background: #141c27 !important; /* Dark scrollbar track */
}

.dark-mode .live-chat-messages::-webkit-scrollbar-thumb {
    background-color: #141c27 !important; /* Darker scrollbar thumb */
}

.dark-mode .chat-timestamp {
    color: #D3D3D3 !important; /* Lighter grey timestamp for dark mode */
}


.dark-mode .fake-chat-display-div   {
    background-color: #141c27 !important; /* Dark background */
    color: #ffffff !important; /* White text */
}

.dark-mode .live-chat-messages {
    background-color: #141c27 !important; /* Dark message background */
    color: #ffffff !important; /* White text */
}