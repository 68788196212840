/* frontend/src/basicChatPage/styles/basicChatTimeline.css */
  .basic-chat-timeline {
    display: flex;
    flex-direction: column-reverse; /* Reverse the order of children to start from bottom */
    justify-content: flex-start; /* Let items grow upwards */

    background-color: #27374D;    
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    max-height: 60vh;
    overflow-y: auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-left : 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
  }
  /* Flexbox for the header */
  .timeline-header {
    display: flex;
    justify-content: space-between; /* Pushes the button to the right */
    align-items: center; /* Vertically centers the items */
    margin-bottom: 5px;
    
    
  } 

  
  .basic-chat-timeline ul {
    padding: 0;
    list-style-type: none;
    
  }
  
  .basic-chat-timeline li {
    margin-bottom: 10px;
  }
  

  
  .basic-chat-timeline .timeline-controls {
    margin-bottom: 15px;
  }
  
  .basic-chat-timeline .form-control,
  .basic-chat-timeline .form-select {
    border-radius: 6px;
    padding: 8px;
    border: 1px solid #DDE6ED;
  }
  
  .basic-chat-timeline .time-increment-buttons button {
    margin-right: 5px;
    background-color: #a3d9a5;
    border-radius: 5px;
    padding: 6px 10px;
  }
  
  .basic-chat-timeline .time-increment-buttons button.active {
    background-color: #5da773;
  }
  


  /* Styling the scrollbar for WebKit browsers (Chrome, Safari, Edge) */
  .basic-chat-timeline::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  .basic-chat-timeline::-webkit-scrollbar-track {
    background-color: #526D82; /* Background color of the track */
    border-radius: 10px;
  }

  .basic-chat-timeline::-webkit-scrollbar-thumb {
    background-color: #9DB2BF; /* Color of the scrollbar thumb */
    border-radius: 10px;
    border: 2px solid #9DB2BF; /* Space around the scrollbar thumb */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for the thumb */
  }

  .basic-chat-timeline::-webkit-scrollbar-thumb:hover {
    background-color: #27374D; /* Darker color when hovering over the thumb */
    border: 2px solid #DDE6ED;
  }

