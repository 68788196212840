/* src/accountPage/styles/Subscription.css */

/* Wrapper for Subscription and Disclaimer Panels */
.subscription-disclaimer-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  max-width: 1200px;
  padding: 20px;
  width: 100%;
}

.custom-price-sub {
  font-size: 2em;
  font-weight: bold;
  color: #000000;
}

/* Subscription Container */
.subscription-container {
  background-color: #141c27;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
}

.subscription-title {
  color: #DDE6ED;
  margin-bottom: 20px;
  text-align: center;
}

.subscription-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px;
  height: 300px;
}

/* Subscription Details */
.subscription-details {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  color: #000000;
  border: 1px solid #000000;
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.5);
}

.subscription-status-title {
  text-align: center;
  margin: 20px 0;
  color: #000000;
  border-bottom: 1px solid #000000;
}

.subscription-details:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Subscription Information Wrapper */
.subscription-info-wrapper {
  margin-top: 20px;
  max-width: 500px;
  width: 100%;
}

.subscription-info-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.subscription-label {
  font-weight: bold;
  color: #DDE6ED;
}

.subscription-value {
  font-weight: bold;
  color: #fcdee8;
}

/* Status Indicator */
.status-indicator {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.icon-subscribed {
  color: #4CAF50;
  font-size: 1.8em;
  margin-right: 10px;
}

.icon-not-subscribed {
  color: #f44336;
  font-size: 1.5em;
  margin-right: 10px;
}

.active-text {
  font-size: 1.2em;
  font-weight: bold;
}

.icon-connected {
  color: #4CAF50; /* Green for connected */
  margin-right: 5px;
}

.icon-not-connected {
  color: #F44336; /* Red for not connected */
  margin-right: 5px;
}

/* Manage Button */
.manage-subscription-container {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.manage-button {
  padding: 12px 25px;
  font-size: 1em;
  background-color: #0A74DA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.manage-button:hover {
  background-color: #005BB5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Subscription Not Subscribed */
.subscription-not-subscribed {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  color: #000000;
  margin-top: 50px;
  flex: 1;
  cursor: pointer;
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.5);

}


/* Subscribe Basic Title */
.subscribe-basic-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #ffffff;
  border-bottom   : 1px solid #ffffff;
}

.subscribe-basic-title h3 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
}
.subscribe-basic-container {
  text-align: center;
}

.subscription-status-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #ffffff;
  border-bottom   : 1px solid #ffffff;
}

.subscription-not-subscribed:hover {
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.5);
}


.subscribe-features {
  margin: 20px 0;
  line-height: 1.6;
}

.new-secondary-heading{
  font-size: 1.2em;
  color: #fcdee8;
  font-weight: bold;
  text-align: center;
}

.highlight-text {
  color: #fc889b;
  font-weight: bold;
}

.highlight-number {
  color: #fc889b;
  font-weight: bold;
}


.subscribe-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #0A74DA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.subscribe-button:hover {
  background-color: #005BB5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

      
.subscribe-info {
  margin-top: 15px;
  font-size: 0.9em;
  color: #9DB2BF;
}
.subscribe-info p {
  margin: 5px 0;
}

/* Disclaimer Container */
.disclaimer-section{
  flex: 1;
  background-color: #526d82;
  border-radius: 8px;
  padding: 20px;
  color: #DDE6ED;
  max-width: 350px;
  width: 100%;
  margin-top: 20px;
  margin-left: 100px;
}

/* Subscription Plan & Next Billing Information */
.subscription-info-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  line-height: 1.6;
}

.subscription-plan,
.subscription-status,
.next-billing {
  font-size: 1.1em;
  color: #F44336;
}

.subscription-info-list p {
  margin: 8px 0;
}
