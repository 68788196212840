.live-controls {
    background-color: #27374D; /* Dark background */

    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;    
}

/* Control Section Layout (Two-column) */
.control-sections {
    display: flex;
    justify-content: space-between;
}

/* Each column takes up equal width */
.control-column {
    width: 98%; /* Two columns, leave space between them */
    background-color: #526d82; /* Match the overall background */
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff; /* Updated text color */
    border: 1px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    
}

/* Align the buttons at the top of each column */
.control-column button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    
    
}

/* Header and description styling for the columns */
.control-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: white; /* Ensure header text is visible */
    
}

.control-description {
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 20px; /* Ensure space between description and next element */
    color: #ffffff; /* Light text */
}

/* Styling for Twitch Username Input */
.twitch-username-input {
    width: 300px; /* Limit the width of the input */
    margin-bottom: 10px;
    color: white;
}

.twitch-username-display {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #27374D; /* Dark background for display */
    color: white;
    border-radius: 5px;
    text-align: center;
}

/* Tabs Styling */
.live-controls .nav-tabs {
    margin-bottom: 1px;
}

.live-controls .tab-content {
    background-color: transparent; /* Updated background color */

    
}

.control-column .twitch-listen {
    background-color: #946bb1 !important; /* Blue-purple shade */
    color: white !important;
    border-color: #946bb1 !important;
}

.control-column .twitch-listen:hover {
    background-color: #bb75eb !important; /* Darker purple on hover */
    border-color: #bb75eb !important;
}

.context-tab {
    border: 1px solid #ffffff;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
}

.events-tab {
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;

}

.events-tab button {
    margin-right: 5px; /* Add space to the right of each button */
}